//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { AMapManager, lazyAMapApiLoaderInstance } from "vue-amap";
import { _setdept, _setdeptupdate } from "@/api/organizational";
let amapManager = new AMapManager();
export default {
  data() {
    let self = this;
    return {
      disab: false,
      btnDisab:false,
      titlt: "",
      form: {
        superDID: "", //上级id
        spname: "",
        lat: "", //经纬度
        lng: "", //经纬度
        opComment: "", //备注
        name: "", //部门名称
      },
      dialogVisible: false,
      address: "",
      searchKey: "", //搜索条件
      amapManager,
      markers: [],
      searchOption: {
        city: "全国",
        citylimit: true,
      },
      center: [121.329402, 31.228667],
      zoom: 17,
      lng: 0,
      lat: 0,
      loaded: false,

      events: {
        init() {
          lazyAMapApiLoaderInstance.load().then(() => {
            self.initSearch();
          });
        },
        // 点击获取地址的数据
        click(e) {
          self.markers = [];
          let { lng, lat } = e.lnglat;
          self.lng = lng;
          self.lat = lat;
          self.center = [lng, lat];
          self.markers.push([lng, lat]);
          // 这里通过高德 SDK 完成。
          let geocoder = new AMap.Geocoder({
            radius: 1000,
            extensions: "all",
          });
          //获取经纬度
          geocoder.getAddress([lng, lat], function (status, result) {
            if (status === "complete" && result.info === "OK") {
              if (result && result.regeocode) {
                //获取地址
                self.address = result.regeocode.formattedAddress;
                self.searchKey = result.regeocode.formattedAddress;
                self.$nextTick();
              }
            }
          });
        },
      },
      // 一些工具插件
      plugin: [
        {
          // 定位
          pName: "Geolocation",
          events: {
            init(o) {
              // o是高德地图定位插件实例
              o.getCurrentPosition((status, result) => {
                if (result && result.position) {
                  // 设置经度
                  self.lng = result.position.lng;
                  // 设置维度
                  self.lat = result.position.lat;
                  // 设置坐标
                  self.center = [self.lng, self.lat];
                  self.markers.push([self.lng, self.lat]);
                  // load
                  self.loaded = true;
                  // 页面渲染好后
                  self.$nextTick();
                }
              });
            },
            click(e) {
              console.log(e);
            },
          },
        },
        {
          // 搜索
          pName: "PlaceSearch",
          events: {
            init(instance) {
            },
          },
        },
      ],
      rules: {
        spname: [
          { required: true, message: "上级组织名称不能为空", trigger: "blur" },
        ],
        name: [
          { required: true, message: "部门名称不能为空", trigger: "blur" },
        ],
        searchKey: [
          { required: true, message: "地址不能为空", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    initSearch() {
      let vm = this;
      let map = this.amapManager.getMap();
      AMapUI.loadUI(["misc/PoiPicker"], function (PoiPicker) {
        var poiPicker = new PoiPicker({
          input: "search",
          placeSearchOptions: {
            map: map,
            pageSize: 10,
          },
          suggestContainer: "searchTip",
          searchResultsContainer: "searchTip",
        });
        vm.poiPicker = poiPicker;
        // 监听poi选中信息
        poiPicker.on("poiPicked", function (poiResult) {
          let source = poiResult.source;
          let poi = poiResult.item;
          if (source !== "search") {
            poiPicker.searchByKeyword(poi.name);
          } else {
            poiPicker.clearSearchResults();
            vm.markers = [];
            let lng = poi.location.lng;
            let lat = poi.location.lat;
            let address = poi.cityname + poi.adname + poi.name;
            vm.center = [lng, lat];
            vm.markers.push([lng, lat]);
            vm.lng = lng;
            vm.lat = lat;
            vm.address = address;
            vm.searchKey = address;
          }
        });
      });
    },
    // 弹框的开关
    dialogswith(data, disab, titlt) {
      if (titlt == "修改") {
        this.disab = disab;
        this.titlt = titlt;
        this.dialogVisible = true;
        this.form = Object.assign(data);
        this.searchKey = data.address;
        this.lat = data.lat;
        this.lng = data.lng;
      } else {
        this.disab = disab;
        this.titlt = titlt;
        this.form.spname = data.newlable; //上级组织名称
        this.form.superDID = data.newid; //上级组织id
        this.dialogVisible = true;
      }
    },
    // 确定按钮的事件
    determine() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.btnDisab = true
          // 新增事件
          if (this.titlt == "新增") {
            let from = {
              superDID: this.form.superDID,
              type: "init",
              lat: this.lat,
              lng: this.lng,
              opComment: this.form.opComment,
              name: this.form.name,
              address: this.searchKey,
            };
            _setdept(from).then((res) => {
              if (res.code === 1) {
                this.$message({
                  message: res.msg,
                  type: "success",
                });
                this.btnDisab = false
                this.$emit("query");
                this.dialogVisible = false;
              }else{
                this.btnDisab = false
              }
            }).catch(()=>{
              this.btnDisab = false
            });
          } else {
            // 修改事件
            let from = {
              type: "updata",
              superDID: this.form.parentDid,
              id: this.form.id,
              lat: this.lat,
              lng: this.lng,
              opComment: this.form.opComment,
              name: this.form.name,
              address: this.searchKey,
            };
            _setdeptupdate(from).then((res) => {
              if (res.code === 1) {
                this.btnDisab = false
                this.$message({
                  message: res.msg,
                  type: "success",
                });
                this.$emit("query");
                this.dialogVisible = false;
              }else{
                this.btnDisab = false
              }
            }).catch(()=>{
              this.btnDisab = false
            });
          }
        }
      });
    },
    close() {
      this.form = {};
      this.$refs["form"].resetFields();
    },
  },
};
