//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  _gettree,
  _getUserList,
  _getdeptDel,
  _geuserDel,
  _scanchilddept,
  _scandeptuser,
} from '../../../../api/organizational'
import Addbmname from './addbmname.vue'
import Addapprover from './addapprover.vue'
import Ewm from './ewm.vue'

export default {
  components: {
    Addbmname,
    Addapprover,
    Ewm,
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val)
    },
  },
  data() {
    return {
      filterText: '', // 自带树节点的搜索
      dataArr: [], //树节点的数据
      defaultProps: {
        children: 'children',
        label: 'label',
      },
      // 搜索的条件
      formInline: {
        user: '',
        region: '',
      },
      // 部门数据
      departmentData: [],
      accountData: [],
      newid: '',
      newlabel: '',
    }
  },
  created() {
    this.newid = sessionStorage.getItem('treeId')
    this.gettree()
  },
  mounted() {},
  methods: {
    ewm(row) {
      this.$refs.ewm.init(row)
    },

    // 初始化树
    gettree() {
      let that = this
      _gettree({}).then(res => {
        this.dataArr = res.data
        this.newid = sessionStorage.getItem('treeId') //初始化的时候 是否是第一次进入  不是的话找点击时存储的id值
          ? sessionStorage.getItem('treeId')
          : res.data[0].id
        this.newlabel = sessionStorage.getItem('treeTable')
          ? sessionStorage.getItem('treeTable')
          : res.data[0].label
        this.bname({
          id: that.newid,
          child: true,
        })
        this.account({ u_did: that.newid })
        this.$nextTick(() => {
          this.$refs.tree.setCurrentKey(this.newid) // 此时的id就是之前你选中的数据对应的id
        })
      })
    },
    // 树节点的点击事件
    handleNodeClick(data) {
      ;(this.formInline = {
        user: '',
        region: '',
      }),
        (this.newid = data.id)
      sessionStorage.setItem('treeId', data.id)
      sessionStorage.setItem('treeTable', data.label)
      this.newlabel = data.label
      let from = { id: data.id, child: true }
      _gettree(from).then(res => {
        if (res.data.child.length != 0) {
          this.departmentData = res.data.child
        } else {
          this.departmentData = [res.data.parent]
        }
      })
      this.account({ u_did: data.id })
    },

    // 初始化进来的部门表格数据
    bname(from) {
      _gettree(from).then(res => {
        if (res.data.child.length != 0) {
          this.departmentData = res.data.child
        } else {
          this.departmentData = [res.data.parent]
        }
      })
    },
    // 初始化进来的账号数据
    account(from) {
      _getUserList(from).then(res => {
        let data = res.data
        data = data.map(item => {
          let roleName = []
          let roleType = []
          Object.keys(item.roleType).forEach(key => {
            // console.log(key,item.roleType[key])
            roleName.push(item.roleType[key])
            roleType.push(Number(key))
          })
          return {
            ...item,
            roleName : String(roleName),
            roleType,
          }
        })
        // console.log(data)
        this.accountData = data
      })
    },
    // 部门查询
    onSubmit() {
      let from = {
        id: this.newid,
        name: this.formInline.user,
      }
      _scanchilddept(from).then(res => {
        res.data.child.forEach(item => {
          item.spname = res.data.parent.name
        })
        this.departmentData = res.data.child
      })
    },
    // 账号查询
    approverSubmit() {
      let from = {
        id: this.newid,
        name: this.formInline.region,
      }
      _scandeptuser(from).then(res => {
        this.accountData = res.data
      })
    },
    // 部门新增的事件
    addname() {
      let that = this
      let from = {
        newid: this.newid ? this.newid : that.newid,
        newlable: that.newlabel,
      }
      this.$refs.Addbmname.dialogswith(from, true, '新增')
    },
    // 修改部门
    moddep(row) {
      this.$refs.Addbmname.dialogswith(
        JSON.parse(JSON.stringify(row)),
        false,
        '修改',
      )
    },
    // 账号的新增
    addapprover() {
      let that = this
      let from = {
        newid: this.newid ? this.newid : that.newid,
        newlable: that.newlabel,
      }
      this.$refs.Addapprover.dialogswith(from, '新增')
    },
    //修改账号信息
    moddepuser(row) {
      console.log(row)

      this.$refs.Addapprover.dialogswith(
        JSON.parse(JSON.stringify(row)),
        '修改',
      )
    },
    // 部门删除
    deptDel(row) {
      this.$confirm('是否确认删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        _getdeptDel({ id: row.id }).then(res => {
          if (res.code == '1') {
            this.gettree()
          }
        })
      })
    },
    // 用户删除
    userDel(row) {
      this.$confirm('是否确认删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        _geuserDel({ id: row.id }).then(res => {
          if (res.code == '1') {
            this.gettree()
          }
        })
      })
    },
    // 筛选事件
    filterNode(value, data) {
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },
    tableHeaderColor({ row, rowIndex, column, columnIndex }) {
      if (rowIndex === 0) {
        return 'background-color:#e8f4ff;font-weight: 400;border-radius: 4px;color: #5f8bcf;height:35px;font-size:15px;font-family: Microsoft YaHei, Microsoft YaHei-Regular;padding:5px 0'
      }
    },
  },
}
