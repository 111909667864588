//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { _addUserList, _getcode } from '@/api/organizational'
export default {
  data() {
    return {
      dialogVisible: false,
      title: '',
      btnDisab: false,
      form: {
        type: 'init',
        pwd: '',
        phone: '',
        name: '',
        uuDid: '',
        opComment: '',
        udname: '',
        useful: '',
        roleType: [],
      },
      options: [],
      disableds: false,
      disabledsq: false,
      rules: {
        udname: [
          { required: true, message: '所属部门不能为空', trigger: 'blur' },
        ],
        name: [
          { required: true, message: '真实姓名不能为空', trigger: 'blur' },
        ],
        phone: [
          { required: true, message: '手机号不能为空', trigger: 'blur' },
          {
            required: true,
            pattern: /^1[123456789]\d{9}$/,
            message: '请输入正确的手机号',
          },
        ],
        // pwd: [{ required: true, message: "密码不能为空", trigger: "blur" }],
        roleType: [
          { required: true, message: '角色不能为空', trigger: 'change' },
        ],
        // useful: [
        //   { required: true, message: "是否有效不能为空", trigger: "change" },
        // ],
      },
    }
  },
  created() {
    // if (JSON.parse(localStorage.getItem('registerType')) == '1') {
    //   this.options = [
    //     { id: 2, name: '需求方值机员' },
    //     { id: 3, name: '需求方负责人' },
    //     { id: 4, name: '需求方管理员' },
    //   ]
    // } else {
    //   this.options = [
    //     { id: 5, name: '服务商工程师' },
    //     { id: 6, name: '服务商主管' },
    //     { id: 7, name: '服务商管理员' },
    //   ]
    // }
  },
  methods: {
    open() {
      console.log('打开', this)
      // _getcode({ para: 'role' ,id : this.form.id || ''}).then(res => {
      //   let psType = JSON.parse(localStorage.getItem('registerType'));
      //   this.options = res.data.filter(item => {
      //     return item.type == psType
      //   })
      //   console.log(this.options)
      //   if(this.form.roleType[0] == 4 || this.form.roleType[0] == 7){
      //     this.disabledsq =  true
      //   }
      // })
      if (JSON.parse(localStorage.getItem('registerType')) == '1') {
        this.options = [
          { id: 2, name: '需求方值机员' },
          { id: 3, name: '需求方负责人' },
          { id: 4, name: '需求方管理员' },
        ]
      } else {
        this.options = [
          { id: 5, name: '服务商工程师' },
          { id: 6, name: '服务商主管' },
          { id: 7, name: '服务商管理员' },
        ]
      }
    },
    dialogswith(data, title) {
      this.title = title
      this.dialogVisible = true
      if (title == '新增') {
        this.form.uuDid = data.newid
        this.form.udname = data.newlable
      } else {
        // 修改
        this.form = Object.assign(data)
        this.disableds = data.useful == '1' ? false : true
        this.disabledsq = data.useful == '1' ? false : true
      }
    },

    // 新增的确认按钮事件
    adduser() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          this.btnDisab = true
          if (this.title == '新增') {
            let from = {
              type: 'init',
              uuDid: this.form.uuDid, //部门id
              // pwd: this.form.pwd,
              phone: this.form.phone,
              name: this.form.name,
              opComment: this.form.opComment,
              // String(this.form.roleType)
              roleType: this.form.roleType,
              useful: this.form.useful,
            }
            _addUserList(from)
              .then(res => {
                if (res.code == '1') {
                  this.dialogVisible = false
                  this.btnDisab = false
                  this.$message({
                    message: res.msg,
                    type: 'success',
                  })
                  this.$emit('query')
                } else {
                  this.btnDisab = false
                }
              })
              .catch(() => {
                this.btnDisab = false
              })
          } else {
            let from = {
              type: 'updata',
              parentDid: this.form.parentDid,
              id: this.form.id,
              // pwd: this.form.pwd,
              phone: this.form.phone,
              name: this.form.name,
              opComment: this.form.opComment,
              // String(this.form.roleType)
              roleType: this.form.roleType,
              useful: this.form.useful,
            }
            _addUserList(from)
              .then(res => {
                if (res.code == '1') {
                  this.btnDisab = false
                  this.$message({
                    message: res.msg,
                    type: 'success',
                  })
                  this.dialogVisible = false
                  this.$emit('query')
                } else {
                  this.btnDisab = false
                }
              })
              .catch(() => {
                this.btnDisab = false
              })
          }
        }
      })
    },
    // 弹框关闭的回调
    close() {
      this.disabledsq = false
      this.form = {
        type: '',
        pwd: '',
        phone: '',
        name: '',
        uuDid: '',
        opComment: '',
        udname: '',
        roleType: [],
      }
      this.$refs['form'].resetFields()
    },
  },
}
