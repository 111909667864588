//
//
//
//
//
//
//
//
//
//
//
//
//

import QRCode from "qrcodejs2";
export default {
  data() {
    return {
      dialogVisible: false,
      from: {},
    };
  },
  methods: {
    init(row) {
      this.dialogVisible = true;
      this.from = row;
    },
    /** 显示二维码图片 */
    showCode() {
      this.creatCode(this.from);
    },
    /** 转换二维码 */
    creatCode(row) {
      let from = [`id:${row.id}`, `address:${row.address}`, `name:${row.name}`];
      // 清除上一张二维码的缓存
      this.$refs.qrcode.innerHTML = "";
      let qrcode = new QRCode(this.$refs.qrcode, {
        text: JSON.stringify(from),
        width: 200,
        height: 200,
        correctLevel: QRCode.CorrectLevel.H,
      });
    },
    /** 弹窗关闭 */
    cancel() {
      this.dialogVisible = false;
    },
  },
};
